<template>
    <div>
        <v-autocomplete
            class="vehiclemake"
            ref="vehiclemake"
            v-model="txt_vehicleMake"
            :items="vehicleMakes"
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            auto-select-first
            placeholder="Kies Merk*"
            return-object
            @keyup.native.enter="onKeyEnter($event)"
            dense>
        </v-autocomplete>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from '@/mixins/data.mixin';
export default {
    name: 'VehicleMakeDropdown',
    props: 
    {
        vehicleMake: Number
    },
    mixins: [DataMixin],
    beforeMount()
    {
        this.updateVehicleMakeValue();
    },
    mounted () {
        let inputField = this.$refs.vehiclemake.$refs.input
        inputField.addEventListener('focus', this.onFocus, true)
    },
    data() {
        return {
            txt_vehicleMake: null,
        }
    },
    computed: {
        ...mapState({
            vehicleMakes: state => state.menuHelper.vehicleMakes
        })
    },
    watch: {
        vehicleMake: 'updateVehicleMakeValue',
        txt_vehicleMake: 'onValueChanged'
    },
    methods:
    {
        onFocus() {
            this.$refs.vehiclemake.isMenuActive = true;
        },
        onKeyEnter(event) {

            if (this.$refs.vehiclemake.isMenuActive) return;

            this.$emit('onKeyEnter', event);
        },
        onValueChanged() {
            if (this.txt_vehicleMake == null) return;

            this.$emit('changed', this.txt_vehicleMake.item1);
        },
        updateVehicleMakeValue() {
            if(this.vehicleMake != null)
            {
                this.txt_vehicleMake = this.vehicleMakes.find(x => x.item1 == this.vehicleMake);
            }
        }
    }
}
</script>