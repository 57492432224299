<template>
    <v-expand-transition>
        <div v-show="isActive">
            <v-row class="mt-0">
                <v-col>
                    <p class="headline mb-2">Voertuiggegevens</p>
                    <div>{{intro}}</div>
                </v-col>
            </v-row>
            <v-form ref="form" lazy-validation v-model="valid" :class="formName">
                <v-text-field 
                    v-model="txt_licensePlate"
                    @blur="onLicensePlateEnter()"
                    @keyup.native.enter="getNextElement($event, formName, 'vehicletype')"
                    label="Kenteken" />
                    
                <VehicleTypeDropdown :vehicleType="txt_vehicleType" @changed="txt_vehicleType = $event" @onKeyEnter="evt => getNextElement(evt, formName, 'vehiclemake')" />
                <VehicleMakeDropdown :vehicleMake="txt_merk" @changed="txt_merk = $event" @onKeyEnter="evt => getNextElement(evt, formName, 'carmodel')" />

                <v-text-field 
                    v-model="txt_model"
                    label="Model*"
                    :rules="[rules.required]"
                    class="carmodel"
                    @keyup.native.enter="getNextElement($event, formName, 'caryear')"
                    required />

                <v-text-field 
                    v-model.number="txt_year"
                    label="Bouwjaar*"
                    @keypress="isNumber($event)"
                    :rules="[rules.required, rules.validRange(txt_year, 1900, getYearOnly(getToday()))]"
                    class="caryear"
                    @keyup.native.enter="getNextElement($event, formName, 'fueltype')"
                    required />
                
                <FuelTypeDropdown 
                    :fuelType="txt_fuelType" 
                    @changed="onFuelTypeChanged($event)" 
                    @onKeyEnter="evt => getNextElement(evt, formName, 'carpower')" />
                
                <v-text-field 
                    v-model.number="txt_power"
                    label="Vermogen in pk*"
                    @keypress="isNumber($event)"
                    :rules="[rules.required, rules.validRange(txt_power, 10, 1000)]"
                    class="carpower"
                    @keyup.native.enter="getNextElement($event, formName, 'carenginecapacity')"
                    required />

                <v-text-field v-if="!isElectricCar(txt_fuelType)"
                    v-model.number="txt_engineCapacity"
                    label="Motorinhoud in cc*"
                    @keypress="isNumber($event)"
                    :rules="[rules.required, rules.validRange(txt_engineCapacity, 100, 10000)]"
                    class="carenginecapacity"
                    @keyup.native.enter="getNextElement($event, formName, 'carcylinder')"
                    required />

                <v-text-field v-if="!isElectricCar(txt_fuelType)"
                    v-model.number="txt_cylinders"
                    label="Cilinders*"
                    @keypress="isNumber($event)"
                    :rules="[rules.required, rules.validRange(txt_cylinders, 1, 16)]"
                    class="carcylinder"
                    required />

            </v-form>
            <div v-if="!isOrderForm">
                <v-btn color="primary" class="mt-6" block @click="saveCar()">
                    Opslaan
                </v-btn>
                <v-row class="justify-center text-center" no-gutters>
                    <v-card-actions>
                        <v-container >
                            <v-row dense>
                                <v-col cols="12">
                                    Geen voertuig toevoegen?
                                    <span class="link" @click="cancel()"> 
                                        Annuleer.
                                    </span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-row>
            </div>
            <div v-if="isOrderForm">
                <v-btn color="primary" block class="mt-3"
                    @click="saveCar()">
                    Rond bestelling af
                </v-btn>
                <v-row class="justify-center text-center" no-gutters>
                    <v-card-actions>
                        <v-container>
                            <v-row dense>
                                    <v-col cols="12">
                                    Foutje gemaakt?
                                    <span class="link" @click="cancel()">Ga terug.</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-row>
            </div>
            
        </div>
    </v-expand-transition>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import FuelTypeDropdown from "@/components/common/options/FuelTypeDropdown";
import VehicleTypeDropdown from "@/components/common/options/VehicleTypeDropdown";
import VehicleMakeDropdown from "@/components/common/options/VehicleMakeDropdown";

export default {
    name: 'CarForm',
    components: 
    {
        FuelTypeDropdown,
        VehicleTypeDropdown,
        VehicleMakeDropdown
    },
    data () {
        return {
            showOptions: false,
            intro: "Pas hieronder de gegevens van uw voertuig aan en druk op opslaan wanneer u klaar bent.",
            txt_vehicleType: 0,
            txt_licensePlate: "", 
            txt_merk: null, 
            txt_model: "",
            txt_year: null,
            txt_engineCapacity: null,
            txt_cylinders: null,
            txt_power: null,
            txt_fuelType: null, //default
            valid: true,
            formName: 'car-form'
        }
    },
    props: 
    {
        index: Number,
        vehicleType: Number,
        licensePlate: String,
        make: Number,
        model: String,
        year: Number,
        engineCapacity: Number,
        cylinders: Number,
        power: Number,
        fuelType: Number,
        id: String,
        isActive: Boolean,
        isNew: Boolean,
        isOrderForm: Boolean
    },
    mixins: [DataMixin, FormatMixin, GlobalMixin, MenuHelperMixin],
    watch: {
        isActive: 'setInitial',
        txt_licensePlate: function (val) {
            this.txt_licensePlate = val.toUpperCase();
        },
    },
    computed: {
        ...mapState({
            fuelTypes: state => state.menuHelper.fuelTypes
        }),
    },
    methods: {
        onFuelTypeChanged(event){
            this.txt_fuelType = event;
        },
        onLicensePlateEnter()
        {
            if(!this.txt_licensePlate || this.txt_licensePlate == null || this.txt_licensePlate.trim() == "") return;

            this.$store.dispatch('vehicleModule/getLicenseplateInformation', this.txt_licensePlate.replaceAll('-', ''))
            .then(data => {
                this.txt_vehicleType = data.vehicleType;
                this.txt_merk = data.make;
                this.txt_model = data.model;
                this.txt_year = data.year;
                this.txt_engineCapacity = data.engineCapacity;
                this.txt_cylinders = data.cylinders;
                this.txt_power = data.power;
                this.txt_fuelType = data.fuelType;
            })
            .catch(error => {
                this.$store.commit('dialog/showDialog', error);
            });
        },
        setInitial()
        {
            if (!this.isActive)
            {
                return;
            }

            this.showOptions = false;
            this.txt_vehicleType = this.vehicleType;
            this.txt_licensePlate = this.licensePlate;
            this.txt_merk = this.make;
            this.txt_model = this.model;
            this.txt_year = this.year;
            this.txt_engineCapacity = this.engineCapacity;
            this.txt_cylinders = this.cylinders;
            this.txt_power = this.power;
            this.txt_fuelType = this.fuelType;
        },
        saveCar()
        {
            
            const valid = this.$refs.form.validate()
            if (valid)
            {
                let request =  { 
                            vehicleType: this.txt_vehicleType,
                            licensePlate: this.txt_licensePlate, 
                            make: this.txt_merk,
                            model: this.txt_model,
                            year: this.txt_year,
                            engineCapacity: this.txt_engineCapacity ? this.txt_engineCapacity : 0,
                            cylinders: this.txt_cylinders ? this.txt_cylinders : 0,
                            power: this.txt_power ? this.txt_power : 0,
                            fuelType: this.txt_fuelType,
                };
                if(this.isNew)
                {
                    this.$emit('create', this.index, request);
                }
                else
                {
                    request.id = this.id;
                    this.$emit('update',  this.index, request);
                }
            }
        },
        cancel()
        {
            this.$emit('cancel', this.index);
        },
    },
}
</script>

<style>
.new-planning .v-expansion-panel-content__wrap
{
    padding: 0px;
    background: #181717;
}

.hide
{
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms !important;
}

.new-planning .add-planning
{
    visibility: visible;
    opacity:1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}
</style>