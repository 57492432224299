<template>
    <div>
       <v-autocomplete
            class="vehicletype"
            ref="vehicletype"
            v-model="txt_vehicleType"
            :items="vehicleTypes"
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            auto-select-first
            placeholder="Kies type"
            return-object
            @keyup.native.enter="onKeyEnter($event)"
            :menu-props="{closeOnContentClick:true}"
            dense>
        </v-autocomplete>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
export default {
    name: 'VehicleTypeDropdown',
    props: 
    {
        vehicleType: Number
    },
    beforeMount()
    {
        this.updateVehicleTypeValue();
    },
    mounted () {
      let inputField = this.$refs.vehicletype.$refs.input
      inputField.addEventListener('focus', this.onFocus, true)
    },
    mixins: [DataMixin],
    data() {
        return {
            txt_vehicleType: null,
        }
    },
    computed: {
        ...mapState({
            vehicleTypes: state => state.menuHelper.vehicleTypes
        })
    },
    watch: {
        vehicleType: 'updateVehicleTypeValue',
        txt_vehicleType: 'onValueChanged'
    },
    methods:
    {
        onFocus() {
            this.$refs.vehicletype.isMenuActive = true;
        },
        onKeyEnter(event) {

            if (this.$refs.vehicletype.isMenuActive) return;

            this.$emit('onKeyEnter', event);
        },
        onValueChanged()
        {
            if (this.txt_vehicleType == null) return;

            this.$emit('changed', this.txt_vehicleType.item1);
        },
        updateVehicleTypeValue()
        {
            if(this.vehicleType != null)
            {
                this.txt_vehicleType = this.vehicleTypes.find(x => x.item1 == this.vehicleType);
            }
        }
    }
}
</script>