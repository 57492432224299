<template>
    <div>
        <v-autocomplete
            class="fueltype"
            ref="fueltype"
            v-model="txt_fuelType"
            :items="fuelTypes"
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            auto-select-first
            placeholder="Brandstof*"
            return-object
            @keyup.native.enter="onKeyEnter($event)"
            dense>
        </v-autocomplete>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'FuelTypeDropdown',
    props: 
    {
        fuelType: Number
    },
    beforeMount()
    {
        this.updateFuelTypeValue();
    },
    mounted () {
        let inputField = this.$refs.fueltype.$refs.input
        inputField.addEventListener('focus', this.onFocus, true)
    },
    data() {
        return {
            txt_fuelType: null,
        }
    },
    mixins: [DataMixin, MenuHelperMixin],
    computed: {
        ...mapState({
            fuelTypes: state => state.menuHelper.fuelTypes
        })
    },
    watch: {
        fuelType: 'updateFuelTypeValue',
        txt_fuelType: 'onValueChanged'
    },
    methods:
    {
        onFocus() {
            this.$refs.fueltype.isMenuActive = true;
        },
        onKeyEnter(event) {

            if (this.$refs.fueltype.isMenuActive) return;

            this.$emit('onKeyEnter', event);
        },
        onValueChanged()
        {
            this.$emit('changed', this.txt_fuelType.item1);
        },
        updateFuelTypeValue()
        {
            if(this.fuelType != null)
            {
                this.txt_fuelType = this.fuelTypes.find(x => x.item1 == this.fuelType);
            }
        }
    }
}
</script>